import { createSelector } from 'reselect';

import { SiteState } from '~/state/types';
import { Experiments } from '~/typings/experiment';

import { PaymentProvider } from '../payment/types';

// Before making changes to user roles, please review their purposes:
// See https://www.notion.so/codecademy/User-Roles-43584b8f68944532975b71a49f8eb7e1

export const selectCurrentUser = (state: SiteState) => state.currentUser;

export const selectCurrentUserId = (state: SiteState) => state.currentUser.id;

export const selectCurrentUserCountry = (state: SiteState) =>
  state.currentUser.location?.geo_country;

export const selectCurrentUserDisplayName = (state: SiteState) =>
  state.currentUser.name || state.currentUser.email;

export const selectCurrentUserPurpose = (state: SiteState) =>
  state.currentUser.onboardingQuestionnaire?.purpose;

export const selectCurrentUserOrganizations = (state: SiteState) =>
  state.currentUser.organizations;

export const selectBusinessPlan = (state: SiteState) =>
  state.currentUser?.businessPlan;

export const selectIsLoggedIn = (state: SiteState) =>
  !state.currentUser.roles.isAnonymous;

export const selectUserJWT = createSelector(
  selectCurrentUser,
  (user) => user.jwt
);

// ts-prune-ignore-next
export const ROLES = Object.freeze({
  anonymous: 'Anonymous',
  free: 'Free',
  pro: 'Pro',
  trial: 'Pro Trial',
});

export const selectIsAdmin = createSelector(
  selectCurrentUser,
  (user) => user.roles.isAdmin
);

// ts-prune-ignore-next
export const selectIsAuthor = createSelector(
  selectCurrentUser,
  (user) => user.roles.isAuthor
);

export const selectIsBusinessAdmin = createSelector(
  selectCurrentUser,
  (user) => user.roles.isBusinessAdmin
);

export const selectHideBusinessAccount = createSelector(
  selectCurrentUser,
  (user) => user.hideBusinessAccount
);

export const selectIsBusinessSsoUser = createSelector(
  selectCurrentUser,
  (user) => user.roles.isBusinessSsoUser
);

// ts-prune-ignore-next
export const selectIsForumModerator = createSelector(
  selectCurrentUser,
  (user) => user.roles.isForumModerator
);

export const selectIsCustomerService = createSelector(
  selectCurrentUser,
  (user) => user.roles.isCustomerService
);

export const selectIsAccountManager = (state: SiteState) =>
  state.currentUser.isAccountManager;

export const selectIsPro = createSelector(
  selectCurrentUser,
  (user) => !!user.roles.isPro
);

export const selectIsProGold = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser.isGold
);

export const selectIsProSilver = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser.isSilver
);

export const selectIsProPaused = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser.subscription?.isProPaused
);

export const selectOneTimePaymentLink = createSelector(
  selectCurrentUser,
  (currentUser) => {
    const sub = currentUser.subscription;
    if (sub?.provider === PaymentProvider.ONETIME) return sub.paymentLink;
    return undefined;
  }
);

export const selectIsFree = createSelector(
  selectCurrentUser,
  (user) => user.roles.isFree
);

export const selectIsAnonymous = createSelector(
  selectCurrentUser,
  (user) => user.roles.isAnonymous
);

export const selectIsVerified = createSelector(
  selectCurrentUser,
  (user) => user.verified
);

export const selectCanUpgradePlan = createSelector(
  selectCurrentUser,
  (user) => user.canUpgradePlan
);

export const selectIsBusinessUser = createSelector(
  selectCurrentUser,
  (user) => user.isBusinessUser
);

export const selectInBusinessTrial = createSelector(
  selectCurrentUser,
  (user) => user.inBusinessTrial
);

export const selectCanAccessPro = selectIsPro;

export const selectCanAccessProContent = selectCanAccessPro;

export const selectPathEnrollments = (state: SiteState) =>
  state.currentUser.filteredPathEnrollments ||
  state.currentUser.pathEnrollments ||
  [];

export const selectPathEnrollmentIds = createSelector(
  selectPathEnrollments,
  (paths) => paths.map((path) => path.id)
);

export const selectCourseEnrollments = (state: SiteState) =>
  state.currentUser.courseEnrollments || [];

export const selectCourseEnrollmentIds = createSelector(
  selectCourseEnrollments,
  (tracks) => (tracks.length > 0 ? tracks.map((track) => track.id) : [])
);

export const selectUserCompletedContainers = (state: SiteState) =>
  state.currentUser.completions;

export const selectUserCompletion = (id: string) =>
  createSelector(
    selectUserCompletedContainers,
    (completions = {}) => completions[id]
  );

export const selectShowTrialCTA = createSelector(
  selectCurrentUser,
  (user) => user.showTrialCTA
);

export const selectUserHasSetTimezone = createSelector(
  selectCurrentUser,
  (user) => !!user.timezoneLocale
);

export const selectUserAppearanceSettings = createSelector(
  selectCurrentUser,
  (user) => user.appearanceSettings
);

export const selectUserInterfaceSettings = createSelector(
  selectCurrentUser,
  (user) => user.interfaceSettings
);

export const selectCurrentUserExperiments = createSelector(
  selectCurrentUser,
  (user) => user.experiments
);

export const selectCanAccessCareerPaths = createSelector(
  selectCurrentUser,
  (user) => user.canAccessCareerPaths
);

export const selectIsEnterpriseUser = createSelector(
  selectCurrentUser,
  (user) => user.isSkillsoftUser
);

export const selectEnterpriseUserUrl = createSelector(
  selectCurrentUser,
  (user) => user.enterpriseUrl
);

export const selectUserEntitlements = createSelector(
  selectCurrentUser,
  (user) => user.entitlements
);

export const selectUserHasQuizEntitlement = createSelector(
  selectUserEntitlements,
  (entitlements) => Boolean(entitlements?.includes('quizzes'))
);

export const selectUserHasPracticeProjectEntitlement = createSelector(
  selectUserEntitlements,
  (entitlements) => Boolean(entitlements?.includes('practice_projects'))
);

export const selectFreeQuizzesTaken = createSelector(
  selectCurrentUser,
  (user) => user.freeQuizzesTaken
);

export const selectFreeProjectsTaken = createSelector(
  selectCurrentUser,
  (user) => user.freeProjectsTaken
);

export const selectUserIsInOpenFirstModuleExperiment = createSelector(
  selectCurrentUserExperiments,
  (experiments) => experiments?.[Experiments.OpenFirstModule] === 'variant'
);

export const selectUserEnrolledInTwoOrMoreContainers = createSelector(
  selectCurrentUser,
  (user) => user.totalEnrollments >= 2
);

export const selectUserIsInTrialCancellationIncentiveExperiment = createSelector(
  selectCurrentUserExperiments,
  (experiments) =>
    experiments?.[Experiments.TrialCancellationIncentive] === 'variant'
);

export const selectUserIsInCycleHHomepageRevampExperiment = createSelector(
  selectCurrentUserExperiments,
  (experiments) => experiments?.[Experiments.CycleHHomepageRevamp] === 'variant'
);
