import pluralize from 'pluralize';
import { CamelCasedPropertiesDeep } from 'type-fest';

import { userAttributes } from '~/libs/userAttributes';
import { ContentItem, ContentItemType } from '~/typings/entities';
import { BusinessTransactionType, Currency } from '~/typings/payment';

const withLECourseRedirect = (path: string, redirectSlug?: string) => {
  if (!redirectSlug) return path;
  return `${path}?course_redirect=${redirectSlug}`;
};

export const homePath = '/';

export const loginPath = '/login';

export const logoutPath = '/logout';

export const logoutPathWithRedirectParams = (redirectPath: string) =>
  `${logoutPath}?redirect=${encodeURIComponent(redirectPath)}`;

export const registerPath = '/register';

export const profilesPath = (profileIdOrUsername: string) =>
  `/profiles/${profileIdOrUsername}`;

export const certificatesPath = (username: string, containerId: string) =>
  `${profilesPath(username)}/certificates/${containerId}`;

export const prodBasePath = 'https://codecademy.com';

const getContentItemTypeRoutePart = (type?: ContentItemType) => {
  return type ? pluralize(type, 2) : '';
};

export const contentItemResumePath = (
  courseSlug: string,
  contentItem?: ContentItem | CamelCasedPropertiesDeep<ContentItem>,
  redirectSlug?: string | undefined,
  anon = false
) => {
  if (!contentItem) return `/courses/${courseSlug}`;

  const resumePath = anon ? '' : '/resume';

  if (contentItem.type === 'lesson') {
    return withLECourseRedirect(
      `/courses/${courseSlug}/lessons/${contentItem.slug}${resumePath}`,
      redirectSlug
    );
  }

  return withLECourseRedirect(
    `/courses/${courseSlug}/${getContentItemTypeRoutePart(contentItem.type)}/${
      contentItem.slug
    }`,
    redirectSlug
  );
};

export const exerciseResumePath = (
  courseSlug: string,
  lessonSlug: string,
  exerciseSlug: string,
  redirectSlug: string
) =>
  withLECourseRedirect(
    `/courses/${courseSlug}/lessons/${lessonSlug}/exercises/${exerciseSlug}`,
    redirectSlug
  );

export const pathContentItemPath = (
  pathSlug: string,
  trackSlug: string,
  moduleSlug: string,
  contentItemType: ContentItemType,
  contentItemSlug: string
) => {
  return createResumeUrlPath({
    pathSlug,
    trackSlug,
    moduleSlug,
    contentItemType,
    contentItemSlug,
  });
};

export const journeyContentItemPath = (
  journeySlug: string,
  pathSlug: string,
  trackSlug: string,
  moduleSlug: string,
  contentItemType: ContentItemType,
  contentItemSlug: string
) => {
  return createResumeUrlPath({
    journeySlug,
    pathSlug,
    trackSlug,
    moduleSlug,
    contentItemType,
    contentItemSlug,
  });
};

export interface ResumeUrlPathParams {
  journeySlug?: string;
  pathSlug?: string;
  trackSlug?: string;
  moduleSlug?: string;
  contentItemType?: ContentItemType;
  contentItemSlug?: string;
}

export const createResumeUrlPath = ({
  journeySlug,
  pathSlug,
  trackSlug,
  moduleSlug,
  contentItemType,
  contentItemSlug,
}: ResumeUrlPathParams) => {
  const contentItemTypeRoute = getContentItemTypeRoutePart(contentItemType);
  const journeyUrl = journeySlug ? `/journeys/${journeySlug}` : '';

  if (
    pathSlug &&
    trackSlug &&
    moduleSlug &&
    contentItemType &&
    contentItemTypeRoute &&
    contentItemSlug
  )
    return `${journeyUrl}/paths/${pathSlug}/tracks/${trackSlug}/modules/${moduleSlug}/${contentItemTypeRoute}/${contentItemSlug}`;

  if (pathSlug && trackSlug && moduleSlug)
    return `${journeyUrl}/paths/${pathSlug}/tracks/${trackSlug}/modules/${moduleSlug}`;

  if (pathSlug && trackSlug)
    return `${journeyUrl}/paths/${pathSlug}/tracks/${trackSlug}`;

  if (pathSlug) return `${journeyUrl}/paths/${pathSlug}`;

  return journeyUrl;
};

export interface contentItemPathParams {
  pathSlug: string;
  trackSlug: string;
  moduleSlug: string;
  contentItem?: ContentItem | CamelCasedPropertiesDeep<ContentItem>;
}

export const syllabusContentItemPath = ({
  pathSlug,
  trackSlug,
  moduleSlug,
  contentItem,
}: Partial<contentItemPathParams>) => {
  const contentItemType = contentItem?.type;
  const contentItemSlug = contentItem?.slug;
  if (pathSlug) {
    return createResumeUrlPath({
      pathSlug,
      trackSlug,
      moduleSlug,
      contentItemType,
      contentItemSlug,
    });
  }
  if (trackSlug && contentItemSlug && contentItemType) {
    return contentItemResumePath(trackSlug, contentItem);
  }
};

export const learnCoursePath = (courseSlug: string) => `/learn/${courseSlug}`;

export const learnModulePath = (courseSlug: string, moduleSlug: string) =>
  `/learn/${courseSlug}/modules/${moduleSlug}`;

export const cheatsheetPath = ({
  courseSlug,
  moduleSlug,
  pathSlug,
}: {
  courseSlug: string;
  moduleSlug: string;
  pathSlug?: string;
}) => {
  return pathSlug
    ? `/learn/paths/${pathSlug}/tracks/${courseSlug}/modules/${moduleSlug}/cheatsheet`
    : `/learn/${courseSlug}/modules/${moduleSlug}/cheatsheet`;
};

export const smartPracticePath = (
  slug: string,
  contentType: 'path' | 'track' | 'journey'
) => `/smart-practice/${contentType}s/${slug}`;

export const learnPath = () => '/learn';

export const coursePracticePath = (trackSlug: string, moduleSlug: string) =>
  `/practice/tracks/${trackSlug}/modules/${moduleSlug}`;

export const catalogPath = '/catalog';

interface PostCheckoutLandingPathParams {
  isPathRedirect: boolean;
  isTrialPlan?: boolean;
  redirectUrl: string;
}

export const postCheckoutLandingPath = ({
  isPathRedirect,
  isTrialPlan,
  redirectUrl,
}: PostCheckoutLandingPathParams): string => {
  if (isTrialPlan && redirectUrl && isPathRedirect) {
    // if user was directed to checkout from a path landing page, send them back there
    return redirectUrl;
  }
  // default post-checkout landing is dashboard
  return learnPath();
};

export function searchPath(query?: string, fromPrevSearch?: string) {
  let path = '/search';
  if (query) {
    path += `?query=${encodeURIComponent(query)}`;
    if (fromPrevSearch) {
      // When users come from search-as-you-type to the search page
      // we want to link the first search_id (for inline results in the top bar)
      // to the second search_id used to get a full set of results on the search page.
      path += `&fromPrevSearch=${encodeURIComponent(fromPrevSearch)}`;
    }
  }
  return path;
}

export const resetProgressPath = (
  type: 'courses' | 'modules' | 'paths' | 'enterprise_modules',
  id: string
) => `/reset-progress/${type}/${id}`;

export const pathsBasePath = '/learn/paths';
export const pathSelectorPath = `${pathsBasePath}/new`;
export const skillPathSelectorPath = '/learn/skill-paths/new';
export const choosePathPagePath = (goal: string) =>
  goal === 'skill' ? skillPathSelectorPath : pathSelectorPath;

export const pathPagePath = (pathSlug: string) =>
  `${pathsBasePath}/${pathSlug}`;

export interface ProPaymentCompletePathRequest {
  couponCode?: string | null;
  planId: string;
  redirectUrl?: string;
  currency?: Currency;
}

export const proPaymentCompletePath = ({
  planId,
  couponCode,
  redirectUrl,
  currency,
}: ProPaymentCompletePathRequest) => {
  const urlParams = new URLSearchParams();
  if (couponCode) urlParams.append('couponCode', couponCode);
  if (redirectUrl) urlParams.append('redirect_url', redirectUrl);
  if (currency) urlParams.append('currency', currency);

  return `/subscriptions/${planId}/paid?${urlParams}`;
};

export interface ProCheckoutPathConfig {
  planId?: string;
  redirectUrl?: string;
  discountCode?: string;
  leftHandSide?: string;
  periods?: string;
  loggedInViaCheckout?: boolean;
  redirectFromVerify?: boolean;
  utm_campaign?: string;
  utm_term?: string;
  utm_content?: string;
  utm_source?: string;
  utm_medium?: string;
  fbclid?: string;
}

export const proCheckoutPath = ({
  planId = userAttributes.payments('defaultPlanId'),
  redirectUrl,
  ...rest
}: ProCheckoutPathConfig) => {
  const url = `/subscriptions/${planId}/checkout`;
  const urlParams = new URLSearchParams();
  if (redirectUrl)
    urlParams.append('redirect_url', decodeURIComponent(redirectUrl));
  Object.keys(rest).forEach((key: string) => {
    const value = (rest as any)[key]?.toString();
    if (value) {
      urlParams.append(key, value);
    }
  });
  return `${url}?${urlParams.toString()}`;
};

export const proCheckoutTrialPath = ({
  planId = userAttributes.payments('defaultTrialPlanId'),
  ...rest
}: ProCheckoutPathConfig) => proCheckoutPath({ planId, ...rest });

export const accountPath = () => '/account';

export interface BillingPathParams {
  changePlan?: boolean;
  redirectUrl?: string;
  renewedPlan?: string;
  showModal?: boolean;
}

export const billingPath = (params?: BillingPathParams) => {
  const billingPath = '/account/billing';
  const urlParams = new URLSearchParams();

  if (params?.changePlan !== undefined)
    urlParams.append('changePlan', params.changePlan.toString());
  if (params?.redirectUrl) urlParams.append('redirect_url', params.redirectUrl);
  if (params?.renewedPlan) urlParams.append('renewedPlan', params.renewedPlan);
  if (params?.showModal)
    urlParams.append('showModal', params.showModal.toString());

  return `${billingPath}?${urlParams.toString()}`;
};

export const passwordBreachPath = '/account/password?pib=true';

export const emailConfirmationPath = (status?: string) =>
  status ? `/confirm?${status}=true` : '/confirm';

export const linkAccountPath = (provider: string) => `/users/auth/${provider}`;

// oauth urls
export const githubAuthenticationUrl = () =>
  '/users/auth/github?scope=public_repo,user:email';

export const googleAuthenticationUrl = () =>
  '/users/auth/google_oauth2?signin=true';

export const facebookAuthenticationUrl = () =>
  '/users/auth/facebook?signin=true';

export const twitterAuthenticationUrl = () => '/users/auth/twitter?signin=true';

export const appleAuthenticationUrl = () => '/users/auth/apple?signin=true';
// other authentication urls

export const forgotPasswordUrl = () => '/secret/new';

export const proLandingPath = '/pro/membership';

export const studentLandingPath = '/student-center';

export const pricingPagePath = '/pricing';

// Footer

export const proPauseHelpPageUrl =
  'https://help.codecademy.com/hc/en-us/sections/360002034793-Pause';

// other urls

export const helpPageUrl = 'https://help.codecademy.com/hc/en-us';
export const helpRequestUrl =
  'https://help.codecademy.com/hc/en-us/requests/new';

export const businessContactPath = '/business/contact';

export const subscriptionsBasePath = (subscriptionId: string) =>
  `/subscriptions/${subscriptionId}`;

export const subscriptionPausePageUrl = (subscriptionId: string) =>
  `${subscriptionsBasePath(subscriptionId)}/pause`;

export const subscriptionUpgradePageUrl = `/subscriptions/upgrade`;

export const trialExtensionIncentiveUrl = (subscriptionId: string) =>
  `${subscriptionsBasePath(
    subscriptionId
  )}/trialExtensionConfirmation?fromCancellation=true`;

export const subscriptionCancellationConfirmUrl =
  '/subscription/cancel/confirm';

interface PaginationParams {
  status?: string;
  page: number;
  sort?: string;
  sort_column?: string;
  plan_status?: string;
  plan_tier?: string;
  plan_type?: string;
  custom_name?: string;
  is_internal_plan?: boolean;
}

// adds pagination params to a fetch url
export const paginationUrl = (
  path: string,
  params: PaginationParams
): string => {
  const urlParams = new URLSearchParams();
  Object.keys(params).forEach((key: string) => {
    const value = (params as any)[key]?.toString();
    if (value) {
      urlParams.append(key, value);
    }
  });
  return `${path}?${urlParams.toString()}`;
};
export const createTrialBusinessPlanUrl = `/business/trial`;
export const fetchBusinessPlansUrl = '/business/plans/fetch_plans';
export const businessPlanUrl = (planId: string) => `/business/plans/${planId}`;

export const resendTrialInvitationUrl = `${createTrialBusinessPlanUrl}/resend_invitation`;

export const businessResetPasswordUrl = `/business/reset_password`;

export interface BusinessPaymentCompletePathRequest {
  planId?: string;
  transactionType: BusinessTransactionType;
  planEndDate: string;
  quantity: number;
  subTotal: number;
}

export const businessPaymentCompletePath = ({
  planId,
  planEndDate,
  quantity,
  transactionType,
  subTotal,
}: BusinessPaymentCompletePathRequest) => {
  const uri = `/business/plans/${planId}/checkout/confirmation`;
  const params = `?planEndDate=${planEndDate}&seatQuantity=${quantity}&transactionType=${transactionType}&subTotal=${subTotal}`;

  return uri + params;
};

export const businessBillingAdminUrl = (planId: string) =>
  `/business/plans/${planId}/billing_admin`;

export const changeCurrencyUrl = (planId: string) =>
  `/business/plans/${planId}/change_currency`;

export const businessSSOConfigCheckUrl = `/business/sso_config/check`;

export const samlAuthUrl = `/users/auth/saml`;

export const businessSSOLoginUrl = (redirectPath?: string) =>
  `/business/sso/login${
    redirectPath ? '?redirect=' + encodeURIComponent(redirectPath) : ''
  }`;

export const businessSSOSignUpUrl = (redirectPath?: string) =>
  `/business/sso/register${
    redirectPath ? '?redirect=' + encodeURIComponent(redirectPath) : ''
  }`;

export const careerJourneyCurriculumPath = (journeySlug: string) =>
  `/career-journey/${journeySlug}`;

export const cumulativeAssessmentPath = (pathSlug: string, trackSlug: string) =>
  `/cumulative-assessment/paths/${pathSlug}/tracks/${trackSlug}`;

export const changelogPath = (
  containerType: 'course' | 'path',
  slug: string
): string => {
  if (containerType === 'path') {
    return `/article/change-log-${slug}-path`;
  }

  return `/article/change-log-${slug}`;
};

export const resumePath = (containerType: string, slug: string) => {
  return containerType === 'track'
    ? `/courses/${slug}/resume`
    : `/paths/${slug}/resume`;
};
